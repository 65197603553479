import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
// import {NgZorroAntdModule} from 'ng-zorro-antd';
import {HttpService} from './http.service';
import {UtilsService} from './utils.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzNoAnimationModule } from 'ng-zorro-antd/core/no-animation';
import { ImagePipe } from '../directive/image/image.pipe';
import { StatusPipe } from './pipes/status.pipe';
import { DisabledPipe } from './pipes/disabled.pipe';
import { DefaultInterceptor } from './cache/net/default.interceptor';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzCascaderModule } from 'ng-zorro-antd/cascader';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzTreeSelectModule } from 'ng-zorro-antd/tree-select';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzTreeModule } from 'ng-zorro-antd/tree';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzListModule } from 'ng-zorro-antd/list';
import { ScrollingModule } from '@angular/cdk/scrolling';

const PIPES = [StatusPipe, DisabledPipe];
/**
 * 公共module，其它module只需导入shared.module
 */
@NgModule({
  imports: [
    ScrollingModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    // NgZorroAntdModule,
    NzButtonModule,
    NzNoAnimationModule,
    NzAutocompleteModule,
    NzCardModule,
    NzTableModule,
    NzToolTipModule,
    NzFormModule,
    NzSelectModule,
    NzCascaderModule,
    NzDatePickerModule,
    NzTreeSelectModule,
    NzPageHeaderModule,
    NzInputNumberModule,
    NzLayoutModule,
    NzInputModule,
    NzDropDownModule,
    NzModalModule,
    NzUploadModule,
    NzTreeModule,
    NzSpinModule,
    NzCheckboxModule,
    NzBreadCrumbModule,
    NzMessageModule,
    NzDividerModule,
    NzIconModule,
    NzStatisticModule,
    NzTabsModule,
    NzListModule
  ],
  exports: [
    NzDividerModule,
    NzMessageModule,
    NzBreadCrumbModule,
    NzCheckboxModule,
    NzTreeModule,
    NzUploadModule,
    NzModalModule,
    NzDropDownModule,
    NzInputModule,
    NzLayoutModule,
    NzInputNumberModule,
    NzPageHeaderModule,
    NzTreeSelectModule,
    NzDatePickerModule,
    NzCascaderModule,
    NzButtonModule,
    NzNoAnimationModule,
    NzCardModule,
    NzTableModule,
    NzToolTipModule,
    NzFormModule,
    NzSelectModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    NzIconModule,
    NzSpinModule,
    NzStatisticModule,
    NzTabsModule,
    NzListModule,
    ScrollingModule,
    // NgZorroAntdModule,
    ImagePipe,
    ...PIPES,
  ],
  declarations: [
    ImagePipe,
    ...PIPES,
  ],
  providers: [
    { provide: 'utilsService', useClass: UtilsService },
    { provide: 'httpService', useClass: HttpService },
    { provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor,multi:true},
  ]
})
export class SharedModule { }
