import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import { CacheService } from './cache/src/cache.service';
import { AppService } from './app.service';
import { EncryptService } from './encrypt.service';

/**
 * 基类http请求
 */
@Injectable()
export class HttpService {

  /**
   * 请求environment KEY
   * @type
   */
  requestKey: any = {
    dashboard: 'dashboard',
    interface6605: 'interface6605',
    interface6702:'interface6702',
    interface6999:'interface6999',
    interface7880:'interface7880',
  };

  constructor(
    public http: HttpClient,
    private cacheSrv: CacheService,
    private appSrv: AppService,
    private encryptSrv: EncryptService,
    ) {
  }

  /**
   * get请求
   * @param url
   * @param param
   * @param reqKey
   * @returns {Promise<Object>}
   */
  get(url, params : Object = {}, reqKey:string = null) {
    return this.http.get(this.getUrl(url,reqKey), params).toPromise();
  }

  /**
   * httpClient post请求
   * @param url 请求地址
   * @param paramJson 请求参数
   * @param moduleKey 请求模块标识
   * @param isLoad 是否加载遮罩层
   * @returns {Promise<T>}
   */
  post(url, paramJson: any = {}, moduleKey: string = null){
    let token = this.appSrv.user;
    const headers = {
      headers: new HttpHeaders({
        Authorization:'Bearer'+' '+token['accessToken']
      }),
    }
    // console.log("headers",headers);
    return this.http.post(this.getUrl(url,moduleKey),
      this.getBody(paramJson),headers).toPromise();
  }

  /**
   * 获取请求头
   */
  getHeaders(){
    let token = this.appSrv.user
    return {
      token:token['accessToken']
    }
  }

  /**
   * 根据moduleKey获取所对应的config配置信息
   * @param url
   * @param moduleKey
   * @returns {string}
   */
  getUrl(url: string, moduleKey: string): string {
    if (moduleKey == null) {
      return url;
    } else {
      let keyJson = JSON.parse(sessionStorage.getItem(moduleKey));
      // console.log(keyJson);
      if (keyJson['test']) {// 测试环境
        return keyJson['url'] + url;

      } else {// 生产环境
        return keyJson['pUrl'] + url;
      }
    }
  }

  getUrls(url: string, moduleKey: string): string {
    if (moduleKey == null) {
      moduleKey = this.requestKey['interface6702'];
    } else {
      let keyJson = JSON.parse(sessionStorage.getItem(moduleKey));
      if (keyJson['test']) {// 测试环境
        return keyJson['url'] + url;

      } else {// 生产环境
        return keyJson['pUrl'] + url;
      }
    }
  }
  
  /**
   * 构造post请求body
   * @param paramJson
   * @returns {any}
   */
  getBody(paramJson) {
    return this.encryptSrv.fineOneEncrypt(paramJson);
  }

  /**
  * post请求
  * @param url
  * @param paramJson
  * @param reqKey
  * @param paramType 参数类型
  * @returns {Promise<Object>}
  */
 postSimple(url, paramJson: Object = {}, reqKey: string = null, paramType: number = 0) {
  return this.http.post(this.getUrl(url, reqKey), this.getParamJsonSimple(paramJson, paramType)).toPromise();
}


/**
* 构造参数类型
* @param paramJson
* @param paramType 参数类型
* @returns {any}
*/
getParamJsonSimple(paramJson, paramType: number = 0) {
  return paramJson;
}

  /**
   * 获取参数
   * @param queryParams
   * @param pageParams
   * @param orderParams
   */
  getParams(queryParams : Object = {},pageParams : Object = {},orderParams : Object = {}){
    return {
      queryParams:queryParams||{},
      pageParams:pageParams||{},
      orderParams:orderParams||{}
    }
  }


  /**
   * upload请求
   * @param url
   * @param params
   * @param reqKey
   * @returns {Promise<Object>}
   */
  upload(url, params, reqKey:string = null) {
    let httpHeaders = new HttpHeaders(this.getHeaders());
    return this.http.post(this.getUrl(url,reqKey),params,{headers:httpHeaders}).toPromise();
  }
}
