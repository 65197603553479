import { Injectable } from '@angular/core';
import { CacheService } from './cache/src/cache.service';
// import { LmJpushService } from './lm-jpush.service';
export interface LmUser {
  access_token: string;
  comType: number;
  companyNo: string;
  customName: string;
  customNo: string;
  expires_in: number;
  granularityRoleNo: string;
  ip: string;
  loginUsername: string;
  logo: string;
  mapType: number;
  parentMenus: LmMenu[];
  realName: string;
  target: string;
  tel: string;
  token_type: string;
}
export interface LmMenu {
  expandable: boolean;
  iconCls: string;
  id: number;
  isSystem: string;
  isValue: boolean;
  leaf: boolean;
  messageView: string;
  orderIndex: number;
  parentId: number;
  recNo: string;
  scope: string;
  text: string;
  textVisual: string;
  view: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private USER_KEY = 'loginUserInfo';
  private COM_KEY = 'comCode';
  private REQUEST_KEY: any = {
    dashboard: 'dashboard',
    interface6205: 'interface6205',
    interface7203: 'interface7203',
    interface7206: 'interface7206',
    interface3000: 'interface3000',
    interface6103: 'interface6103',
    interface6218: 'interface6218',
    interface6601: 'interface6601',
    interface8087: 'interface8087',
    interface6637: 'interface6637',
    interface6636: 'interface6636',

    interface6803:'interface6803',
    interface6806:'interface6806',
    interface6201:'interface6201',
  };

  get user() {
    return this.cacheSrv.get(this.USER_KEY, { mode: 'none' }) || {};
  }

  set user(value: LmUser) {
    this.cacheSrv.set(this.USER_KEY, value);
    // this.jPushTargetConfig(value);
  }

  get comCode() {
    return this.cacheSrv.get(this.COM_KEY, { mode: 'none' });
  }

  set comCode(value: LmUser) {
    this.cacheSrv.set(this.COM_KEY, value);
  }

  get dashboard() {
    return this.cacheSrv.get(this.REQUEST_KEY.dashboard, { mode: 'none' });
  }

  get interface6205() {
    return this.cacheSrv.get(this.REQUEST_KEY.interface6205, { mode: 'none' });
  }

  get interface7203() {
    return this.cacheSrv.get(this.REQUEST_KEY.interface7203, { mode: 'none' });
  }

  get interface7206() {
    return this.cacheSrv.get(this.REQUEST_KEY.interface7206, { mode: 'none' });
  }

  get interface3000() {
    return this.cacheSrv.get(this.REQUEST_KEY.interface3000, { mode: 'none' });
  }

  get interface6103() {
    return this.cacheSrv.get(this.REQUEST_KEY.interface6103, { mode: 'none' });
  }

  get interface6218() {
    return this.cacheSrv.get(this.REQUEST_KEY.interface6218, { mode: 'none' });
  }

  get interface6601() {
    return this.cacheSrv.get(this.REQUEST_KEY.interface6601, { mode: 'none' });
  }

  get interface8087() {
    return this.cacheSrv.get(this.REQUEST_KEY.interface8087, { mode: 'none' });
  }

  get interface6637() {
    return this.cacheSrv.get(this.REQUEST_KEY.interface6637, { mode: 'none' });
  }

  get interface6636() {
    return this.cacheSrv.get(this.REQUEST_KEY.interface6636, { mode: 'none' });
  }

  get interface6803() {
    return this.cacheSrv.get(this.REQUEST_KEY.interface6803, { mode: 'none' });
  }
  
  get interface6806() {
    return this.cacheSrv.get(this.REQUEST_KEY.interface6806, { mode: 'none' });
  }

   
  get interface6201() {
    return this.cacheSrv.get(this.REQUEST_KEY.interface6201, { mode: 'none' });
  }

  
 


  constructor(
    private cacheSrv: CacheService,
    // private jpushSrv: LmJpushService,
  ) {}

  // jPushTargetConfig(user: LmUser) {
  //   if (user) {
  //     this.jpushSrv.cleanTags().then(x => {
  //       this.jpushSrv.addTags([user.target]);
  //     });
  //   }else{
  //     this.jpushSrv.cleanTags();
  //   }
  // }
}
