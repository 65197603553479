/**
 * 编辑行disabled管道
 */
import { Pipe, PipeTransform } from "@angular/core";
import { isNullOrUndefined } from "util";

@Pipe({ name: "disabledPipe" })
export class DisabledPipe implements PipeTransform {
  transform(value: boolean): boolean {
    // console.log("value",value);

    if(isNullOrUndefined(value)){
      // console.log("value1",value);
        value = true;
      }
      return value;
    
  }
}
